<template>
  <div id="headerComp">
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-light py-3">
        <div class="container">
          <a class="navbar-brand" href="/">
            <img src="@/assets/images/logo.png" class="logo">
            <span class="logoFont">{{ isMobile?'蜜蜂优车-二手车跨境整合服务商':'蜜蜂优车｜二手车跨境整合服务商' }}</span>
          </a>
          <button class="navbar-toggler navbar-toggler-end" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false"
            aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
          <div class="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item mx-2">
                  <span :class="indexActive?'nav-link active':'nav-link'" @click="jumpPage('home')"><i></i>首页</span>
                </li>
                <li class="nav-item mx-2">
                  <span :class="aboutActive?'nav-link active':'nav-link'" @click="jumpPage('about')"><i></i>关于我们</span>
                </li>
                <li class="nav-item mx-2">
                  <span :class="companyNewsActive?'nav-link active':'nav-link'"
                    @click="jumpPage('companyNews')"><i></i>公司动态</span>
                </li>
                <li class="nav-item mx-2">
                  <span :class="recruitActive?'nav-link active':'nav-link'"
                    @click="jumpPage('recruit')"><i></i>联系我们</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script type="text/javascript">
import mixins from '@/mixins'
export default {
  name: "headerComp",
  mixins: [mixins],
  components: {
  },
  props: {
    fullpageRef: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      indexActive: false,
      aboutActive: false,
      companyNewsActive: false,
      recruitActive: false,
      fullpageInstance: null
    }
  },
  computed: {
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  watch: {
    '$route.path': {
      handler(toPath, fromPath) {
        switch (toPath) {
          case '/index':
            this.indexActive = true
            if (!this.isMobile) {
              this.$nextTick(() => {
                this.fullpageInstance.moveTo(1)
              })
            }
            break;
          case '/about':
            this.aboutActive = true
            break;
          case '/companyNews':
            this.companyNewsActive = true
            break;
          case '/recruit':
            this.recruitActive = true
            break;
          default:
            this.companyNewsActive = true
            break;
        }
        bus.$on('pageType', (id) => {
          setTimeout(() => {
            if (id && toPath != '/index') {
              this.scrollToCard(id);
            } else {
              if (!this.isMobile) {
                this.$nextTick(() => {
                  if (id == 'cpjs') {
                    this.fullpageInstance.moveTo(1)
                  } else if (id == 'jjfa') {
                    this.fullpageInstance.moveTo(2)
                  } else if (id == 'mfyc') {
                    this.fullpageInstance.moveTo(4)
                    this.scrollToCard(id);
                  } else if (id == 'hzhb') {
                    this.fullpageInstance.moveTo(4)
                    this.scrollToCard(id);
                  }
                })
              }
            }
          }, 50);
        })
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  methods: {
    scrollToCard(id) {
      // 使用 Vue 的 $refs 来获取卡片元素，并滚动到其位置
      const cardElement = document.getElementById(id)
      if (cardElement) {
        cardElement.scrollTop = 0
        // 使用原生的 scrollIntoView 方法来滚动到元素位置
        cardElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  },
  created() {
  },
  mounted() {
    if (!this.isMobile) {
      bus.$on('fullpageReady', (fullpageInstance) => {
        this.fullpageInstance = fullpageInstance
      })
    }
  },
  destroyed() {
  }
}
</script>

<style lang="less" scoped>
.header {
  position: relative;
  top: 0;
  z-index: 9999;
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

  .nav-link {
    cursor: pointer;
  }
}

@media (min-width:992px) {
  .header {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 240px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

    .nav-link {
      cursor: pointer;
    }
  }
}

.logo {
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
}

.mx-2 {
  margin: 0 32px !important;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #FADB14;
  position: relative;

  i {
    display: none;
  }
}

@media (min-width:992px) {
  .navbar-light .navbar-nav .nav-link.active {
    color: #000;
    position: relative;

    i {
      position: absolute;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 1px;
      display: block;
      border-bottom: 1px solid #000;
    }
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: #FADB14;
  }
}

.logoFont {
  color: #000;
  margin-left: 8px;
  font-size: 16px;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-toggler {
  padding: 0 2px;
}

@media (min-width: 992px) {
  .logo {
    width: 100px
  }

  .logoFont {
    color: #000;
    margin-left: 20px;
    font-size: 20px;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}
.offcanvas.offcanvas-end{
  width:50%;
}
</style>
