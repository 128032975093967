const mixins = {
  components: {},
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {
    // this.headerScroll()
    document.body.removeAttribute('style')
  },
  methods: {
    headerScroll() {
      window.addEventListener('scroll', function () {
        var header = document.querySelector('.header');
        // 当页面向下滚动超过100px时，添加.scrolling类
        if (window.scrollY > 1) {
          header.classList.add('scrolling');
        } else {
          header.classList.remove('scrolling');
        }
      })
    },
    jumpPage(type) {
      switch (type) {
        case 'about':
          this.$router.push('/about')
          break;
        case 'companyNews':
          this.$router.push('/companyNews')
          break;
        case 'recruit':
          this.$router.push('/recruit')
          break;
        default:
          this.$router.push('/index')
          break;
      }
    }
  }
}

export default mixins;