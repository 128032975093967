<template>
  <div id="footerComp">
    <footer class="text-white bg-cover bg-center" v-if="!isMobile">
      <div class="dark">
        <div class="overlay-content">
          <div class="container py-5">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <h3>蜜蜂优车</h3>
                <p style="margin-top:20px">
                  <img :src="img1" alt="二维码" width="120px" height="120px">
                  <img :src="img2" alt="二维码" style="margin-left:20px" width="120px" height="120px">
                </p>
                <p class="mb-1">
                  <span class="text-small">联系电话</span>
                  <strong class="text-big">400-080-7727</strong>
                </p>
                <p class="mb-1" style="margin-top:20px">
                  <span class="text-small">邮箱</span>
                  <strong class="text-big">develop@tidycar.cn</strong>
                </p>
              </div>
              <div class="col-lg-8 col-md-8">
                <div class="flexRow">
                  <div class="block">
                    <h6>公司首页</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','cpjs')">产品介绍</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','jjfa')">解决方案</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','mfyc')">蜜蜂优车</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','hzhb')">合作伙伴</a></li>
                    </ul>
                  </div>
                  <div class="block">
                    <h6>关于我们</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('about','fzlc')">发展历程</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('about','sscjs')">收拾车介绍</a></li>
                    </ul>
                  </div>
                  <div class="block">
                    <h6>公司动态</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('companyNews','gsdt')">公司动态</a></li>
                    </ul>
                  </div>
                  <div class="block">
                    <h6>联系我们</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('contact','gsdz')">公司地址</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('contact','swqt')">商务洽谈</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('contact','rlzp')">人力招聘</a></li>
                    </ul>
                  </div>
                </div>
                <div class="footRow">
                  <p>收拾车 (北京) 汽车服务有限公司</p>
                  <p>京ICP备20000029号</p>
                  <p>地址：北京市朝阳区盛华机动车检测场 (收拾车)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer" v-if="isMobile">
      <strong>联系电话：400-080-7727</strong>
      <span>收拾车 (北京) 汽车服务有限公司 京ICP备20000029号</span>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "footerComp",
  components: {
  },
  data() {
    return {
      img1: require('../../assets/images/erCode1.png'),
      img2: require('../../assets/images/erCode2.jpg')
    }
  },
  computed: {
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  methods: {
    jumpPage(page, type) {
      if (page == 'index') {
        this.$router.push({ path: '/index' })
      } else if (page == 'about') {
        this.$router.push({ path: '/about' })
      } else if (page == 'companyNews') {
        this.$router.push({ path: '/companyNews' })
      } else if (page == 'contact') { 
        this.$router.push({ path: '/recruit' })
      }
      bus.$emit('pageType',type)
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.footer{
  width:100%;
  height:80px;
  background: #F5F5F5;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  strong{
    font-size: 12px;
  }
  span{
    font-size: 10px;
  }
}
.dark {
  background: #262626 !important;
}

.text-small,
.text-big {
  display: block;
}

.text-small {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
}

.text-big {
  font-size: 24px;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;

  .block {


    ul {
      color: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }
  }
}

@media (min-width: 992px) {
  .flexRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;

    .block {
      margin-left: 100px;

      ul {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.footRow {
  margin: 50px 0 0 auto;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  width: 100%;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

@media (min-width: 992px) {
  .footRow {
    margin: 50px 0 0 auto;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    width: 68%;
    text-align: right;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
  }
}
</style>
