import request from '@/utils/request'
// 获取轮播图列表
export function getBannerList(params) {
  return request({
    url: '/website/carouselPicture',
    method: 'get',
    params
  })
}

// 获取新闻动态列表
export function getCompanyNewsList(params) {
  return request({
    url: '/website/article',
    method: 'get',
    params
  })
}

// 获取新闻动态列表
export function getCompanyNewsDetail(id) {
  return request({
    url: `/website/article/${id}`,
    method: 'get'
  })
}

// 获取关于我们列表
export function getAboutUsList(params) {
  return request({
    url: '/website/aboutUs',
    method: 'get',
    params
  })
}

// 获取招聘列表
export function getRecruitList(params) {
  return request({
    url: '/website/recruitment/list',
    method: 'get',
    params
  })
}